import React from 'react'

// Components
import Blog from 'components/flex/Posts/Blog'
import Projects from 'components/flex/Posts/Projects'
import Vacancies from 'components/flex/Posts/Vacancies'
import Realestate from 'components/flex/Posts/Realestate'

interface PostsShellProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_PostTypes
  location?: any
}

interface PostProps {
  [key: string]: any
}

const PostsShell: React.FC<PostsShellProps> = ({ fields, location = {} }) => {
  const types: PostProps = {
    blog: Blog,
    projects: Projects,
    vacancies: Vacancies,
    realestate: Realestate,
  }

  if (!fields.styletype || !types[fields.styletype]) {
    return null
  }

  const Component = types[fields.styletype]

  return (
    <Component
      key={`${location ? location.pathname : 'flex'}`}
      fields={fields}
      location={location}
    />
  )
}

export default PostsShell
