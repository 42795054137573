import React, { useState } from 'react'
import styled from 'styled-components'
import { graphql, useStaticQuery } from 'gatsby'
import { motion } from 'framer-motion'
import Plaatjie from '@ubo/plaatjie'

// Elements
import ButtonPrimary from 'components/elements/Buttons/ButtonPrimary'
import ParseContent from 'components/shared/ParseContent'
import NavLink from 'components/shared/NavLink'
import BlogBink, {
  useBlogBink,
  BlogBinkButton,
  BlogBinkPosts,
} from '@ubo/blog-bink'

// Images
import ArrowRight from 'img/arrowright.inline.svg'

const FilterWrapper = styled.div`
  background-color: ${({ theme }) => theme.color.primary};
  font-family: ${({ theme }) => theme.font.family.primaryMedium};

  &:after {
    content: '';
    position: absolute;
    left: 100%;
    top: 0px;
    bottom: 0px;
    width: 2000px;
    background-color: ${({ theme }) => theme.color.primary};
  }
`

interface VacanciesProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_PostTypes
}

const Vacancies: React.FC<VacanciesProps> = ({ fields }) => {
  const {
    allWpVacancy,
    // eslint-disable-next-line
  } = useStaticQuery<GatsbyTypes.vacanciesQueryQuery>(graphql`
    query vacanciesQuery {
      allWpVacancy(sort: { order: DESC, fields: date }) {
        edges {
          node {
            ...generalVacancyFragment
          }
        }
      }
    }
  `)

  const posts: unknown = allWpVacancy.edges

  return (
    <section className="mb-5">
      <BlogBink
        posts={posts as BlogBinkPosts}
        id="vacancies"
        limit={Number(fields.limit)}
      >
        <div className="container py-lg-5">
          {fields.description && (
            <div className="row mb-5 pb-lg-5">
              <div className="d-flex">
                <Content content={fields.description} className="me-5 pe-5 " />
                <FilterWrapper className="position-relative" />
              </div>
            </div>
          )}

          <div className="row">
            <BlogGrid fields={fields} />
          </div>
        </div>
      </BlogBink>
    </section>
  )
}

interface BlogGridProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_PostTypes
}

const BlogGrid: React.FC<BlogGridProps> = ({ fields }) => {
  const blogBink = useBlogBink()

  return (
    <div className="container">
      <div className="row">
        {blogBink.posts.map((post, index) => {
          const { node }: any = post

          return (
            <div key={post.node.id} className="col-sm-6 col-lg-4 mb-5 pb-4">
              <BlogGridPost node={node} index={index} />
            </div>
          )
        })}
      </div>

      {blogBink.showMoreButton && (
        <div className="mt-5 text-center">
          <ButtonPrimary to="/" isCustom>
            <BlogBinkButton>{fields.loadmoretext}</BlogBinkButton>
          </ButtonPrimary>
        </div>
      )}
    </div>
  )
}

const Image = styled(Plaatjie)`
  z-index: -1;
  @media (min-width: 992px) {
    height: 300px;
  }
  @media (max-width: 767px) {
    height: 220px;
  }
  @media (max-width: 575px) {
    height: auto;
  }
`

const Description = styled.div`
  z-index: 2;
  background-color: ${({ theme }) => theme.color.light};
  box-shadow: rgba(0, 0, 0, 0.25) 0px 0px 4px 1px;
  margin-top: -3.5rem;
  min-height: 171px;

  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    font-family: ${({ theme }) => theme.font.family.primaryBold};
    font-size: 18px;
    margin-bottom: 1rem;
  }

  & svg {
    width: 17.35px;
    height: 13.81px;
    & path {
      fill: ${({ theme }) => theme.color.primary};
    }
  }
`

const Content = styled(ParseContent)`
  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    @media (min-width: 1400px) {
      font-size: 28px;
    }
  }

  & p {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;

    @media (min-width: 1400px) {
      -webkit-line-clamp: 2;
    }
    @media (max-width: 1399px) {
      -webkit-line-clamp: 3;
    }
    @media (max-width: 1199px) {
      -webkit-line-clamp: 4;
    }
    @media (max-width: 991px) {
      -webkit-line-clamp: 3;
    }
    @media (max-width: 767px) {
      -webkit-line-clamp: 5;
    }
    @media (max-width: 575px) {
      -webkit-line-clamp: 3;
    }
  }
`

interface BlogGridPostProps {
  // eslint-disable-next-line
  node: {
    id: string
    title: string
    uri: string
    vacancydetail: {
      highlightedimage: any
      shortdescription: string
    }
    categories: any
  }
  index: number
}

const BlogGridPost: React.FC<BlogGridPostProps> = ({ node, index }) => {
  const { vacancydetail } = node

  const [currentIndex, setCurrentIndex] = useState<number | null>(null)

  const current: boolean = index === currentIndex

  return (
    <>
      <div className="position-relative">
        <Image image={vacancydetail.highlightedimage} alt="" />
      </div>
      <motion.div
        onHoverStart={() => setCurrentIndex(index)}
        onHoverEnd={() => setCurrentIndex(null)}
      >
        <NavLink to={node.uri || '/'}>
          <Description className="position-relative p-4 mx-4">
            <h2>{node.title}</h2>
            <Content content={vacancydetail.shortdescription} />
            <div className="mt-auto">
              <motion.div
                animate={current ? { x: '90%' } : { x: 0 }}
                transition={{
                  duration: 0.5,
                  type: 'spring',
                  stiffness: 40,
                }}
                className="d-flex"
              >
                <ArrowRight className="mt-4" />
              </motion.div>
            </div>
          </Description>
        </NavLink>
      </motion.div>
    </>
  )
}

export default Vacancies
