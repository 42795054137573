import React, { useState } from 'react'
import styled from 'styled-components'
import { graphql, useStaticQuery } from 'gatsby'
import { motion, AnimatePresence } from 'framer-motion'

// Elements
import NavLink from 'components/shared/NavLink'
import ParseContent from 'components/shared/ParseContent'
import BlogBink, {
  useBlogBink,
  BlogBinkPosts,
  BlogBinkFilter,
  BlogBinkButton,
} from '@ubo/blog-bink'
import Plaatjie from '@ubo/plaatjie'

// Images
import Close from 'img/close.inline.svg'
import ButtonPrimary from 'components/elements/Buttons/ButtonPrimary'

const Content = styled(ParseContent)`
  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    @media (min-width: 992px) {
      font-size: 28px;
    }
  }
`

const FilterWrapper = styled.div`
  background-color: ${({ theme }) => theme.color.primary};
  font-family: ${({ theme }) => theme.font.family.primaryMedium};

  &:after {
    content: '';
    position: absolute;
    left: 100%;
    top: 0px;
    bottom: 0px;
    width: 2000px;
    background-color: ${({ theme }) => theme.color.primary};
  }
`

const MobileFilterCollapsed = styled.div`
  position: fixed;
  bottom: 0;
  background: ${({ theme }) => theme.color.primary};
  padding: 0.75rem 1rem;
  border-top-left-radius: 36px;
  filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.5));
  z-index: 4;
  width: 100%;
  & h2 {
    font-family: ${({ theme }) => theme.font.family.primaryMedium};
    font-size: 18px;
  }

  & span {
    color: ${({ theme }) => theme.color.light};
    font-size: 12px;
  }
`

const MobileFilterExpanded = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: ${({ theme }) => theme.color.primary};
  z-index: 4;
  padding: 1.5rem 1rem;
  border-top-left-radius: 36px;
  filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.5));

  & h2 {
    font-family: ${({ theme }) => theme.font.family.primaryMedium};
    font-size: 24px;
  }

  & button {
    border: 2px solid transparent;
    background-color: ${({ theme }) => theme.color.light};
    opacity: 1;
    border-radius: 12px;
    text-align: center !important;
    margin-bottom: 1rem;
    padding: 0.5rem;
    width: 75%;
    margin: 0.5rem auto;
  }
`

interface ProjectsProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_PostTypes
}

const Projects: React.FC<ProjectsProps> = ({ fields }) => {
  const {
    allWpProject,
    allWpProjectcategory,
    // eslint-disable-next-line
  } = useStaticQuery<GatsbyTypes.projectsQueryQuery>(graphql`
    query projectsQuery {
      allWpProject(sort: { order: DESC, fields: date }) {
        edges {
          node {
            ...generalProjectFragment
          }
        }
      }
      allWpProjectcategory(filter: { name: { nin: "Uncategorized" } }) {
        edges {
          node {
            id
            name
            wpParent {
              node {
                databaseId
                name
              }
            }
          }
        }
      }
    }
  `)

  const posts = allWpProject.edges

  const categories = allWpProjectcategory.edges

  let filter: string | null = null
  let id: string

  switch (fields.maincategory) {
    case 'business':
      filter = 'zakelijk'
      id = 'business'
      break
    case 'private':
      filter = 'particulier'
      id = 'private'
      break
    default:
      filter = null
      id = 'projects'
  }

  let filteredPosts: any = []
  const filteredCategories: Array<any> = []

  if (filter) {
    posts
      .filter(
        (post: any) =>
          post.node.projectdetail.projecttype &&
          post.node.projectdetail.projecttype.includes(id)
        // post.node.projectcategories.nodes.map(
        //   (node: any) => node.wpParent.node.name.toLowerCase() === filter
        // )
      )
      .forEach((post: any) => {
        filteredPosts.push(post)
      })

    categories
      .filter(
        (category) =>
          category.node.wpParent?.node?.name?.toLowerCase() === filter
      )
      .forEach((category) => {
        filteredCategories.push(category)
      })
  }

  if (!filter) {
    filteredPosts = posts

    categories
      .filter((category) => category.node.wpParent)
      .forEach((category) => {
        filteredCategories.push(category)
      })
  }

  return (
    <section className="mb-5 pb-lg-5">
      <BlogBink
        posts={filteredPosts as BlogBinkPosts}
        categories={filteredCategories}
        categoryName="projectcategories"
        id={id}
        limit={Number(fields.limit)}
      >
        <div className="container py-lg-5">
          <div className="row justify-content-end">
            <div className="col-xl-8">
              <div className="d-flex flex-column mb-3">
                <div className="row align-items-center">
                  <div className="d-sm-block d-none">
                    <div className="col-sm-4">
                      <Content content={fields.description} className="me-4" />
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="d-sm-flex d-none">
                      <FilterWrapper className="position-relative px-5 py-lg-4 py-3">
                        <BlogFilters />
                      </FilterWrapper>
                    </div>
                    <div className="d-flex d-sm-none">
                      <MobileFilterHandler
                        filteredCategories={filteredCategories}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <BlogGrid fields={fields} />
        </div>
      </BlogBink>
    </section>
  )
}

interface MobileFilterHandlerProps {
  filteredCategories: any
}

const MobileFilterHandler: React.FC<MobileFilterHandlerProps> = ({
  filteredCategories,
}) => {
  const [mobileFilterOpen, setMobileFilterOpen] = useState<boolean>(false)

  const mobileFilterAnimations = {
    collapsed: {
      height: '0vh',
    },
    open: {
      height: '30vh',
    },
  }

  const blogBink = useBlogBink()

  const selectedCategories: Array<any> = []

  filteredCategories.forEach((filteredCategory: any) => {
    // @ts-ignore
    if (blogBink.selectedFilters.includes(filteredCategory.node.id)) {
      selectedCategories.push(filteredCategory)
    }
  })

  return (
    <>
      <AnimatePresence>
        {!mobileFilterOpen && (
          <MobileFilterCollapsed onClick={() => setMobileFilterOpen(true)}>
            <div className="d-flex justify-content-between align-items-center">
              <h2>Filter op categorie</h2>
              <div className="d-flex flex-column mt-2 me-3">
                <span>Huidige categorie(ën):</span>
                {selectedCategories.length === 0 && <span>- Alles</span>}
                {selectedCategories.map((selectedCategory, index: number) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <span key={index}>{`- ${selectedCategory.node.name}`}</span>
                ))}
              </div>
            </div>
          </MobileFilterCollapsed>
        )}
      </AnimatePresence>

      {mobileFilterOpen && (
        <MobileFilterExpanded>
          <div className="d-flex justify-content-between">
            <h2>Filter op categorie(ën)</h2>
            <Close
              className="me-3"
              style={{ height: 25, width: 25 }}
              onClick={() => setMobileFilterOpen(false)}
            />
          </div>

          <motion.div
            variants={mobileFilterAnimations}
            initial="collapsed"
            animate="open"
            exit="collapsed"
            transition={{ type: 'spring', damping: 20 }}
            tabIndex={-1}
            style={{ overflowY: 'scroll' }}
            className="d-flex flex-column me-3"
          >
            <div className="d-flex flex-column pt-4 mt-3">
              <BlogFilters />
            </div>
          </motion.div>
        </MobileFilterExpanded>
      )}
    </>
  )
}

const StyledBlogFilter = styled(BlogBinkFilter)<{ selected: boolean }>`
  @media (min-width: 576px) {
    opacity: ${(props) => (props.selected ? 1 : 0.4)};
    color: ${(props) =>
      props.selected ? props.theme.color.light : props.theme.color.dark};
    &:hover {
      @media (min-width: 992px) {
        color: ${({ theme }) => theme.color.light};
        opacity: 1;
      }
    }
  }
  @media (max-width: 575px) {
    color: ${(props) =>
      props.selected ? props.theme.color.primary : props.theme.color.dark};
    border: ${(props) =>
      props.selected
        ? `2px solid ${props.theme.color.dark} !important`
        : 'unset'};
  }
`

interface BlogFilterProps {
  node?: any
}

const BlogFilters: React.FC<BlogFilterProps> = () => {
  const blogBink = useBlogBink()

  return (
    <>
      {blogBink.categories.map((category: BlogFilterProps) => (
        <StyledBlogFilter
          key={category.node.id}
          id={category.node.id}
          selected={blogBink.isSelected(category.node.id)}
          className="me-lg-4 text-start text-md-center"
        >
          {category.node.name}
        </StyledBlogFilter>
      ))}
    </>
  )
}

interface BlogGridProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_PostTypes
}

const BlogGrid: React.FC<BlogGridProps> = ({ fields }) => {
  const blogBink = useBlogBink()

  return (
    <div className="row">
      {blogBink.posts.length > 0 &&
        blogBink.posts.map((post) => {
          const { node }: any = post

          return (
            <div key={post.node.id} className="col-sm-6 col-md-4 mt-4">
              <BlogGridPost node={node} />
            </div>
          )
        })}
      {blogBink.posts.length < 1 && (
        <>
          <h2>Helaas!</h2>
          <p>
            We kunnen geen projecten vinden. Mogelijk zijn er nog geen projecten
            in deze categorie. Probeer een andere categorie of probeer het later
            nog eens.
          </p>
        </>
      )}
      <div className="mt-5">
        {blogBink.showMoreButton && (
          <div className="text-center">
            <ButtonPrimary to="/" isCustom>
              <BlogBinkButton>{fields.loadmoretext}</BlogBinkButton>
            </ButtonPrimary>
          </div>
        )}
      </div>
    </div>
  )
}

const Post = styled(NavLink)`
  color: ${({ theme }) => theme.color.light};

  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    font-family: ${({ theme }) => theme.font.family.primaryMedium};
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    @media (min-width: 992px) {
      font-size: 24px;
    }
    @media (max-width: 991px) {
      font-size: 18px;
      line-height: 22px;
    }
  }

  & span {
    @media (min-width: 992px) {
      font-size: 18px;
    }
  }

  &:hover {
    color: ${({ theme }) => theme.color.light};
    & img {
      transform: scale(1.1);
      transition-property: transform;
      transition-duration: 0.3s;
      transition-timing-function: ease-in-out !important;
    }
  }
  @media (min-width: 1400px) {
    min-height: 420px;
  }
  @media (max-width: 1399px) {
    min-height: 355px;
  }
  @media (max-width: 1199px) {
    min-height: 295px;
  }
  @media (max-width: 991px) {
    min-height: 215px;
  }
  @media (max-width: 767px) {
    min-height: 245px;
  }
  @media (max-width: 575px) {
    min-height: auto;
  }
`

const PostInner = styled.div`
  z-index: 3;
  margin-top: -6.3rem;
  background: rgb(29, 26, 26);
  background: linear-gradient(
    0deg,
    rgba(29, 26, 26, 0.727328431372549) 70%,
    rgba(255, 255, 255, 0) 100%
  ) !important;
`

const ImageWrapper = styled.div`
  bottom: 0;
  left: 0;
  width: 100%;
  @media (min-width: 576px) {
    position: absolute;
  }
  @media (max-width: 575px) {
    position: relative;
  }
`

const Image = styled(Plaatjie)`
  position: relative;
  @media (min-width: 576px) {
    height: 100%;
  }
  @media (max-width: 575px) {
    height: 225px;
  }
`

interface BlogGridPostProps {
  // eslint-disable-next-line
  node: {
    id: string
    title: string
    uri: string
    projectdetail: {
      highlightedimage: any
    }
    projectcategories: {
      nodes: any
    }
  }
}

const BlogGridPost: React.FC<BlogGridPostProps> = ({ node }) => {
  const [isHover, setHover] = useState<boolean>(false)

  const categories = node.projectcategories.nodes.map((edge: any) => edge)

  return (
    <Post
      to={node.uri}
      className="position-relative d-flex flex-column justify-content-end h-100"
    >
      <motion.div
        role="button"
        onHoverStart={() => setHover(true)}
        onHoverEnd={() => setHover(false)}
        className="h-100"
      >
        <ImageWrapper className="h-100 overflow-hidden">
          <motion.div
            initial={{ scale: 1 }}
            animate={isHover ? { scale: 1.1 } : { scale: 1.0 }}
            exit={{ scale: 1 }}
            transition={{ duration: 0.25 }}
            className="h-100"
          >
            <Image image={node.projectdetail.highlightedimage} alt="" />
          </motion.div>
        </ImageWrapper>
      </motion.div>

      <PostInner className="position-relative p-4">
        <h2>{node.title}</h2>
        {categories.map((edge: any, index: number) => (
          <>
            {categories.length > 1 ? (
              <>
                {index !== categories.length - 1 ? (
                  <span>{`${edge.name}, `}</span>
                ) : (
                  <span>{edge.name}</span>
                )}
              </>
            ) : (
              <span>{edge.name}</span>
            )}
          </>
        ))}
      </PostInner>
    </Post>
  )
}

export default Projects
