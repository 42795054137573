import React, { useState } from 'react'
import styled from 'styled-components'
import { graphql, useStaticQuery } from 'gatsby'
import { motion } from 'framer-motion'

// Elements
import NavLink from 'components/shared/NavLink'
import ParseContent from 'components/shared/ParseContent'
import BlogBink, {
  useBlogBink,
  BlogBinkPosts,
  BlogBinkButton,
} from '@ubo/blog-bink'
import Plaatjie from '@ubo/plaatjie'
import ButtonPrimary from 'components/elements/Buttons/ButtonPrimary'

const Content = styled(ParseContent)`
  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    @media (min-width: 992px) {
      font-size: 28px;
    }
  }

  & p {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }
`

interface RealestateProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_PostTypes
}

const Realestate: React.FC<RealestateProps> = ({ fields }) => {
  const {
    allWpRealestate,
    // eslint-disable-next-line
  } = useStaticQuery<GatsbyTypes.realestateQueryQuery>(graphql`
    query realestateQuery {
      allWpRealestate(sort: { order: DESC, fields: date }) {
        edges {
          node {
            ...generalRealestateFragment
          }
        }
      }
    }
  `)

  const posts: unknown = allWpRealestate.edges

  return (
    <section className="mb-5 pb-lg-5">
      <BlogBink
        posts={posts as BlogBinkPosts}
        limit={Number(fields.limit)}
        id="realestate"
      >
        <div className="container py-lg-5">
          <BlogGrid fields={fields} />
        </div>
      </BlogBink>
    </section>
  )
}

interface BlogGridProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_PostTypes
}

const BlogGrid: React.FC<BlogGridProps> = ({ fields }) => {
  const blogBink = useBlogBink()

  return (
    <>
      <div className="row">
        {blogBink.posts.length > 0 &&
          blogBink.posts.map((post) => {
            const { node }: any = post

            return (
              <div key={post.node.id} className="col-sm-6 col-md-4 mt-4">
                <BlogGridPost node={node} />
              </div>
            )
          })}
        {blogBink.posts.length < 1 && (
          <>
            <h2>Helaas!</h2>
            <p>
              We kunnen geen projecten vinden. Mogelijk zijn er nog geen
              projecten in deze categorie. Probeer een andere categorie of
              probeer het later nog eens.
            </p>
          </>
        )}
      </div>
      {blogBink.showMoreButton && (
        <div className="mt-5 text-center">
          <ButtonPrimary to="/" isCustom>
            <BlogBinkButton>{fields.loadmoretext}</BlogBinkButton>
          </ButtonPrimary>
        </div>
      )}
    </>
  )
}

const Post = styled(NavLink)`
  color: ${({ theme }) => theme.color.light};

  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    font-family: ${({ theme }) => theme.font.family.primaryMedium};
    font-size: 24px;
  }

  & span {
    font-size: 18px;
  }

  &:hover {
    color: ${({ theme }) => theme.color.light};
    & img {
      transform: scale(1.1);
      transition-property: transform;
      transition-duration: 0.3s;
      transition-timing-function: ease-in-out !important;
    }
  }
  @media (min-width: 1400px) {
    min-height: 420px;
  }
  @media (max-width: 1399px) {
    min-height: 355px;
  }
  @media (max-width: 1199px) {
    min-height: 295px;
  }
  @media (max-width: 991px) {
    min-height: 215px;
  }
  @media (max-width: 767px) {
    min-height: 245px;
  }
  @media (max-width: 575px) {
    min-height: auto;
  }
`

const PostInner = styled.div`
  z-index: 3;
  margin-top: -8.2rem;
  background: rgb(29, 26, 26);
  background: linear-gradient(
    0deg,
    rgba(29, 26, 26, 0.727328431372549) 70%,
    rgba(255, 255, 255, 0) 100%
  ) !important;
`

const ImageWrapper = styled.div`
  bottom: 0;
  left: 0;
  z-index: 2;
  width: 100%;

  @media (min-width: 576px) {
    position: absolute;
  }
  @media (max-width: 575px) {
    position: relative;
  }
`

const Image = styled(Plaatjie)`
  position: relative;
  @media (min-width: 576px) {
    height: 100%;
  }
  @media (max-width: 575px) {
    height: 225px;
  }
`

interface BlogGridPostProps {
  // eslint-disable-next-line
  node: {
    id: string
    title: string
    uri: string
    realestatedetail: {
      shortdescription: string
      highlightedimage: any
    }
    projectcategories: {
      nodes: any
    }
  }
}

const BlogGridPost: React.FC<BlogGridPostProps> = ({ node }) => {
  const [isHover, setHover] = useState<boolean>(false)

  return (
    <Post
      to={node.uri}
      className="position-relative d-flex flex-column justify-content-end h-100"
    >
      <motion.div
        role="button"
        onHoverStart={() => setHover(true)}
        onHoverEnd={() => setHover(false)}
        className="h-100"
        style={{
          background:
            'linear-gradient(0deg,rgba(29, 26, 26, 0.727328431372549) 0%,rgba(255, 255, 255, 0) 63%)',
        }}
      >
        <ImageWrapper className="h-100 overflow-hidden">
          <motion.div
            initial={{ scale: 1 }}
            animate={isHover ? { scale: 1.1 } : { scale: 1.0 }}
            exit={{ scale: 1 }}
            transition={{ duration: 0.25 }}
            className="h-100"
          >
            <Image image={node.realestatedetail.highlightedimage} alt="" />
          </motion.div>
        </ImageWrapper>
      </motion.div>

      <PostInner className="position-relative p-4">
        <h2>{node.title}</h2>
        <Content content={node.realestatedetail.shortdescription} />
      </PostInner>
    </Post>
  )
}

export default Realestate
